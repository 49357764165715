import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import { buyerApi } from '../../common/api';
import { redirect_buy_product, redirect_index_product } from '../../common/api/shopify/redirect';
import { redirect_jumpseller_checkout } from '../../common/api/jumpseller/redirect';
import { PaymentTransaction, Company } from '../../app/type';

// Utils
import { setDocumentTitle, useQuery, formatCurrency } from '../../common/utils';

// Components
import { Container, Grid, Typography, Button, Divider } from '@mui/material';
import Response from './components/Responses/Response';

// Assets
import styles from './Payment.module.scss';
import responseStyles from './components/Responses/Response.module.scss';
import variables from '../../common/styles/variables.module.scss';

const SubscriptionSuccess = (): React.ReactElement => {
  setDocumentTitle('Pago Exitoso');
  const [payment, setPayment] = useState<PaymentTransaction>();
  const [loading, setLoading] = useState<boolean>(false);
  const query = useQuery();
  const text = (): string => {
    if (query?.inscription_only) return 'Tu tarjeta ha sido inscrita exitosamente.';
    else if (query?.delayed_wp)
      return 'Te has suscrito existosamente. Te enviaremos un correo cuando debas pagar la primera cuota.';
    return 'Tu pago fue exitoso';
  };

  useEffect(() => {
    if (query?.payment_id) {
      setLoading(true);
      buyerApi.payments
        .show(query.payment_id)
        .then((data) => setPayment(data.data))
        .finally(() => setLoading(false));
    }
  }, [query]);

  return (
    <Grid container>
      <Response
        loading={loading}
        backgroundStyle={responseStyles.blueBg}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/circle_check.svg'}
        content={
          payment ? (
            <div className={styles.paymentDetailContainer}>
              <Grid item container xs={12}>
                <Typography variant="h2">
                  ¡El pago realizado a {payment.company.name} fue exitoso!
                </Typography>
              </Grid>
              <div className={styles.table}>
                <div className={styles.title}>
                  <Typography className={styles.title}>Detalles del pago</Typography>
                </div>
                <div className={styles.info}>
                  <Typography className={styles.description}>Monto:</Typography>
                  <Typography className={styles.data}>{formatCurrency(payment.amount)}</Typography>
                </div>
                <Divider className={styles.divider} />
                <div className={styles.info}>
                  <Typography className={styles.description}>Fecha de pago:</Typography>
                  <Typography className={styles.data}>{payment.payment_date}</Typography>
                </div>
                <Divider className={styles.divider} />
                <div className={styles.info}>
                  <Typography className={styles.description}>Medio de pago:</Typography>
                  <Typography className={styles.data}>{payment.payment_method}</Typography>
                </div>
              </div>
            </div>
          ) : (
            <Grid item xs={12} className={styles.subscriptionRes}>
              <Typography variant="h4" mb={4}>
                ¡Muchas gracias!
              </Typography>
              <Typography component="div" variant="h6">
                {text()}
              </Typography>
            </Grid>
          )
        }
      />
    </Grid>
  );
};

const SubscriptionError = (): React.ReactElement => {
  setDocumentTitle('Pago No Realizado');
  const query = useQuery();
  const message =
    ' Esto puede deberse a diversas razones, te recomendamos verificar los detalles del pago o contactar a tu institución financiera para más información.';
  const text = (): string => {
    if (query?.inscription_only) return 'Lamentamos informarte que tu tarjeta ha sido rechazada.';
    return 'Lamentamos informarte que tu pago ha sido rechazado.';
  };
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.redBgLinear}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/warning_white.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Pago rechazado!
            </Typography>
            <Typography component="div" variant="h6">
              <span className={responseStyles.strongText}>{text()}</span>
              {}
              {query.message ? <Typography>Motivo: {query.message}</Typography> : message}
            </Typography>
            {query.product_id && (
              <Button
                disableElevation
                size="medium"
                variant="contained"
                color="primary"
                sx={{ marginTop: '20px' }}
                component={Link}
                to={`/products/${query.product_id}`}
              >
                Volver a intentar
              </Button>
            )}
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionInactive = (): React.ReactElement => {
  setDocumentTitle('Link Caducado');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.redBgRadial}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/plug.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Link expirado!
            </Typography>
            <Typography component="div" variant="h6">
              <span className={responseStyles.strongText}>
                Lo sentimos, el enlace de pago ha caducado.
              </span>{' '}
              Si necesitas realizar un pago o completar una transacción, te recomendamos ponerte en
              contacto con la persona o empresa.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionFailed = (): React.ReactElement => {
  setDocumentTitle('Error de conexión');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.greenBg}
        backgroundImgStyle={responseStyles.responseBg2}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/sad_face.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Error al cargar el servicio!
            </Typography>
            <Typography component="div" variant="h6">
              Lo sentimos, se produjo un{' '}
              <span className={responseStyles.strongText}>
                error al obtener los datos del servicio.{' '}
              </span>
              Por favor,{' '}
              <span className={responseStyles.strongText}>ponte en contacto con el vendedor</span>{' '}
              para resolver este problema.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionTerminated = (): React.ReactElement => {
  setDocumentTitle('Servicio Inactivo');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.redBgLinear}
        backgroundImgStyle={responseStyles.responseBg3}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/box_error.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Producto no disponible!
            </Typography>
            <Typography component="div" variant="h6">
              Lamentablemente, este{' '}
              <span className={responseStyles.strongText}>producto no está disponible</span> en este
              momento. Te recomendamos ponerte en contacto con el vendedor si quieres continuar con
              la compra.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const DebtPaid = (): React.ReactElement => {
  setDocumentTitle('Sin Deudas 🎉');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.blueBg}
        backgroundImgStyle={responseStyles.responseBg4}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/claps.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Felicitaciones!
            </Typography>
            <Typography component="div" variant="h6">
              <span className={responseStyles.strongText}>
                Has completado el pago de todas las cuotas que debías pagar al día de hoy 🎉
              </span>
            </Typography>
            <Typography mt={5} sx={{ fontWeight: '300', textAlign: 'center' }}>
              *Si consideras que hay algún error, por favor comunícate con el vendedor para
              resolverlo
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const BlockedCompany = (): React.ReactElement => {
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.greenBg}
        backgroundImgStyle={responseStyles.responseBg2}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/box_search.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Lo sentimos!
            </Typography>
            <Typography component="div" variant="h6">
              El vendedor{' '}
              <span className={responseStyles.strongText}>no puede proveer este servicio</span> en
              este momento. Ponte en contacto con el vendedor para resolver el problema.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionShopifySuccess = (): React.ReactElement => {
  setDocumentTitle('Suscripción Exitosa');

  const [company, setCompany] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(false);
  const query = useQuery();

  useEffect(() => {
    if (query?.company_id) {
      setLoading(true);
      buyerApi.companies
        .show(query.company_id)
        .then((data) => setCompany(data.data))
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <div className={`${styles.responseContainer}`}>
      {loading && (
        <div className={styles.loaderContainer}>
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
            alt="Logo Zafepay"
          />
        </div>
      )}

      <div className={styles.zpLogoContainer}>
        <a href="https://zafepay.com">
          <img
            src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_white.png"
            alt="Logo Zafepay"
            className={styles.zpLogo}
          />
        </a>
      </div>
      <Container maxWidth="md" sx={{ mt: 5 }}>
        <Grid container>
          <Grid item xs={12} className={styles.subscriptionRes}>
            {company && company.image ? (
              <img
                src={company.image}
                alt={`Logo ${company.name}`}
                className={styles.companyLogo}
              />
            ) : (
              <img
                src="https://storage.googleapis.com/onlypays-public/assets/images/successful_payment.svg"
                alt="imagen éxito"
              />
            )}
            <Typography component="div">
              <p color={variables.error}>
                <strong>¡Felicitaciones!</strong>
              </p>
              Tu suscripción ha sido procesada de forma exitosa.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const ShopifyBuyLoading = (): React.ReactElement => {
  setDocumentTitle('Cargando subscripción');
  const location = useLocation();

  useEffect(() => {
    redirect_buy_product(location.search)
      .then((response: any) => {
        window.location.href = response.data.link;
      })
      .catch(() => {
        window.location.href = '/subscription/failed';
      });
  }, []);

  return (
    <Grid container>
      <div className={styles.loaderContainer}>
        <img
          src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
          alt="Logo Zafepay"
        />
      </div>
    </Grid>
  );
};

const ShopifyIndexLoading = (): React.ReactElement => {
  setDocumentTitle('Cargando subscripción');
  const location = useLocation();

  useEffect(() => {
    redirect_index_product(location.search)
      .then((response: any) => {
        window.location.href = response.data.link;
      })
      .catch(() => {
        window.location.href = '/subscription/failed';
      });
  }, []);

  return (
    <Grid container>
      <div className={styles.loaderContainer}>
        <img
          src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
          alt="Logo Zafepay"
        />
      </div>
    </Grid>
  );
};

const JumpsellerCheckout = (): React.ReactElement => {
  setDocumentTitle('Cargando subscripción');
  const location = useLocation();
  useEffect(() => {
    redirect_jumpseller_checkout(location.search)
      .then((response: any) => {
        window.location.href = response.redirect_url;
      })
      .catch((error: any) => {
        console.error(error);
        window.location.href = '/subscription/failed';
      });
  }, []);
  return (
    <Grid container>
      <div className={styles.loaderContainer}>
        <img
          src="https://storage.googleapis.com/onlypays-public/assets/images/zafepay_logo_color.png"
          alt="Logo Zafepay"
        />
      </div>
    </Grid>
  );
};

const OverStock = (): React.ReactElement => {
  setDocumentTitle('Sin stock');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.yellowBg}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/no_stock_box.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Producto no disponible!
            </Typography>
            <Typography component="div" variant="h6">
              Lamentamos informarte que este producto{' '}
              <span className={responseStyles.strongText}>no está disponible</span> en este momento.
              Por favor, ponte en contacto con el vendedor para resolver esta situación.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const InscriptionsLimitReached = (): React.ReactElement => {
  setDocumentTitle('Inscripciones agotadas');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.yellowBg}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/no_stock_box.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Máximo de inscripciones alcanzado!
            </Typography>
            <Typography component="div" variant="h6">
              Lamentamos informarte que{' '}
              <span className={responseStyles.strongText}>
                el límite de inscripciones de este servicio fue alcanzado.
              </span>{' '}
              Por favor, ponte en contacto con el vendedor para resolver esta situación.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const SubscriptionCanceledSuccess = (): React.ReactElement => {
  setDocumentTitle('Suscripción Cancelada');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.greenBg}
        backgroundImgStyle={responseStyles.responseBg5}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/smile_file.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Cancelación exitosa!
            </Typography>
            <Typography component="div" variant="h6">
              Te informamos que tu suscripción ha sido cancelada con éxito.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const PendingSubscription = (): React.ReactElement => {
  setDocumentTitle('Transferencia Pendiente');
  const [textToShow, setTextToShow] = useState<string>('');
  const query = useQuery();

  useEffect(() => {
    if (['bt_bank_login', 'pending_add'].includes(query.status)) {
      setTextToShow(
        `Actualmente, el estado del pago se encuentra como "${query.message?.toLowerCase()}". Si el proceso continúa y la transferencia es aceptada, recibirás una notificación por correo electrónico.`
      );
    } else {
      setTextToShow(
        `Tu solicitud ha sido ingresada con éxito y está con estado "${query.message?.toLowerCase()}". El proceso de inscripción puede demorar hasta 48 horas hábiles, cuando haya finalizado recibirás una notificación por correo electrónico.`
      );
    }
  }, [query.status]);

  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.blueBg}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/wallet_clock.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              {query.inscription ? '¡Procesando la inscripción!' : '¡Procesando el pago!'}
            </Typography>
            <Typography component="div" variant="h6">
              {textToShow}
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const PacInscriptionError = (): React.ReactElement => {
  setDocumentTitle('Inscripción Fallida');
  return (
    <Grid container>
      <Response
        loading={false}
        backgroundStyle={responseStyles.redBgLinear}
        backgroundImgStyle={responseStyles.responseBg1}
        icon={'https://storage.googleapis.com/onlypays-public/assets/images/warning_white.svg'}
        content={
          <Grid item xs={12} className={styles.subscriptionRes}>
            <Typography variant="h4" mb={4}>
              ¡Inscripción fallida!
            </Typography>
            <Typography component="div" variant="h6">
              <span className={responseStyles.strongText}>
                Lamentamos informarte que la inscripción del PAC ha fallado.
              </span>{' '}
              Esto puede deberse a diversas razones, te recomendamos contactar al vendedor.
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const ProcessingPayment = (): React.ReactElement => {
  setDocumentTitle('Procesando pago');
  const query = useQuery();

  useEffect(() => {
    if (query?.payable_id) {
      buyerApi.payments
        .payablePayment(query.payable_id, query.payable_type)
        .then((data) => {
          window.location.href = `/subscription/success?payment_id=${data.data.uuid}`;
        })
        .catch(() => {
          window.location.href = '/subscription/error';
        });
    } else if (query?.delayed_wp) {
      window.location.href = '/subscription/success?delayed_wp=true';
    }
  }, [query]);
  return (
    <Grid container>
      <Response loading={true} backgroundStyle={responseStyles.none} icon={''} content={<></>} />
    </Grid>
  );
};

const Subscription = (): React.ReactElement => {
  return (
    <Switch>
      <Route path="/subscription/success">
        <SubscriptionSuccess />
      </Route>
      <Route path="/subscription/shopify_success">
        <SubscriptionShopifySuccess />
      </Route>
      <Route path="/subscription/shopify_buy_loading">
        <ShopifyBuyLoading />
      </Route>
      <Route path="/subscription/shopify_index_loading">
        <ShopifyIndexLoading />
      </Route>
      <Route path="/subscription/jumpseller_checkout">
        <JumpsellerCheckout />
      </Route>
      <Route path="/subscription/error">
        <SubscriptionError />
      </Route>
      <Route path="/subscription/inactive">
        <SubscriptionInactive />
      </Route>
      <Route path="/subscription/failed">
        <SubscriptionFailed />
      </Route>
      <Route path="/subscription/terminated">
        <SubscriptionTerminated />
      </Route>
      <Route path="/subscription/debt_paid">
        <DebtPaid />
      </Route>
      <Route path="/subscription/blocked_company">
        <BlockedCompany />
      </Route>
      <Route path="/subscription/canceled_success">
        <SubscriptionCanceledSuccess />
      </Route>
      <Route path="/subscription/over_stock">
        <OverStock />
      </Route>
      <Route path="/subscription/inscriptions_limit_reached">
        <InscriptionsLimitReached />
      </Route>
      <Route path="/subscription/pac_failed">
        <PacInscriptionError />
      </Route>
      <Route path="/subscription/pending">
        <PendingSubscription />
      </Route>
      <Route path="/subscription/processing_payment">
        <ProcessingPayment />
      </Route>
    </Switch>
  );
};

export default Subscription;
