import client from '../client';
export const redirect_jumpseller_checkout = (query: string): any => {
  return client({
    method: 'get',
    url: `/v1/external/jumpseller/integrations/checkout${query}`,
  })
    .then(({ data: body }: { data: Promise<any> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ error });
      throw error;
    });
};
export default {
  redirect_jumpseller_checkout,
};
