import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Typography, useMediaQuery, Box, CircularProgress } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from '../../../app/hooks';
import { ClientState } from '../clientSlice';
import { clientApi } from '../../../common/api';
import { formatCurrency } from '../../../common/utils';
import { ApiObject, Card, PAC, SubscriptionBuyer } from '../../../app/type';

import styles from './Product.module.scss';
import variables from '../../../common/styles/variables.module.scss';
import { hexToRgb, pluralInstallmentsWord } from '../utils';
import { useShopCartContext } from '../../../common/contexts/ShopCart';
import StatementsCart from '../components/StatementsCart';
import StatementsTable from '../components/StatementsTable';
import CardsPopup from '../components/CardsPopup';
import PaymentsPopup from '../components/PaymentsPopup';

interface SubscriptionBuyerShowProps {
  cards: Card[];
  PACs: PAC[];
}

const SubscriptionShow = ({ cards, PACs }: SubscriptionBuyerShowProps): React.ReactElement => {
  const isMobile = useMediaQuery(`(max-width:${variables.breakpointMedium})`);
  const [loading, setLoading] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openPaymentsPopup, setOpenPaymentsPopup] = useState<boolean>(false);

  const history = useHistory();
  const { client, company } = useSelector(({ client }: { client: ClientState }) => client);
  const { statementsCart, amountToPay } = useShopCartContext();
  const { subscriptionBuyerId } = useParams<{ subscriptionBuyerId: string }>();
  const [subscriptionBuyer, setSubscriptionBuyer] = useState<SubscriptionBuyer>();
  const totalSubscription = subscriptionBuyer?.subscription?.render_amount;
  const pluralInstallments = pluralInstallmentsWord(subscriptionBuyer?.total_statements);
  const debtCount = subscriptionBuyer?.total_debt_statements;
  const pluralDebt = pluralInstallmentsWord(debtCount);
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  const goBack = () => {
    history.goBack();
  };

  const showQueries = () => {
    clientApi.subscriptionBuyers
      .show(subscriptionBuyerId, client?.id || '')
      .then((data: ApiObject<SubscriptionBuyer>) => {
        setSubscriptionBuyer(data.data);
      })
      .catch(() => {
        history.replace('/client/error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    showQueries();
  }, []);

  return (
    <div className={`${styles.indexContainer} ${amountToPay > 0 && styles.amountToPayHeight}`}>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              sx={{
                color: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            />
          </Box>
        </div>
      ) : (
        <Fragment>
          <div className={styles.backRow} onClick={goBack}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={styles.icon}
              style={{
                color: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            />
          </div>
          <div className={styles.headerRow}>
            <div className={styles.icon}>
              <div
                className={styles.buildingIconCircle}
                style={{
                  backgroundColor: company?.payment_design?.background_color
                    ? company?.payment_design?.background_color
                    : '#4653e3',
                }}
              >
                <FontAwesomeIcon icon={faBoxOpen} className={styles.buildingIcon} />
              </div>
            </div>
            <div>
              <div className={styles.planName}>
                <Typography>{subscriptionBuyer?.subscription?.name}</Typography>
              </div>
              <div className={styles.paymentRow}>
                <div>
                  <Typography className={styles.title}>Medio de pago inscrito</Typography>
                  <Typography className={styles.name}>
                    {subscriptionBuyer?.card
                      ? `XXXX XXXX XXXX ${subscriptionBuyer?.card.last_four_digits}`
                      : subscriptionBuyer?.pac
                      ? `${
                          subscriptionBuyer?.pac?.account_type
                        } •••• ${subscriptionBuyer?.pac?.account_number?.slice(-4)}`
                      : 'Sin medio de pago inscrito'}
                  </Typography>
                </div>
                {!isMobile && (
                  <div
                    className={styles.editButton}
                    onClick={() => setOpenPopup(true)}
                    style={{
                      color: company?.payment_design?.background_color
                        ? company?.payment_design?.background_color
                        : '#4653e3',
                      borderColor: company?.payment_design?.background_color
                        ? company?.payment_design?.background_color
                        : '#4653e3',
                    }}
                  >
                    <Typography>Editar medio de pago</Typography>
                  </div>
                )}
              </div>
              {isMobile && (
                <div
                  className={styles.editButton}
                  onClick={() => setOpenPopup(true)}
                  style={{
                    color: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                    borderColor: company?.payment_design?.background_color
                      ? company?.payment_design?.background_color
                      : '#4653e3',
                  }}
                >
                  <Typography>Editar medio de pago</Typography>
                </div>
              )}
            </div>
          </div>
          <div className={styles.installmentsCard}>
            <div
              className={`${styles.installmentsBox} ${styles.showInstallmentsBox}`}
              style={{
                background: cardsBackgroundColor,
              }}
            >
              <div className={styles.leftCol}>
                <Typography className={styles.debtTitle}>{`Total: ${formatCurrency(
                  totalSubscription
                )}`}</Typography>
                <div className={styles.installmentsRow}>
                  <Typography
                    className={styles.number}
                  >{`${subscriptionBuyer?.total_paid_statements}/${subscriptionBuyer?.total_statements} ${pluralInstallments}`}</Typography>
                  {isMobile && debtCount && debtCount > 0 ? (
                    <div className={styles.adviceFilled}>
                      <Typography>{`${debtCount} ${pluralDebt} vencida${
                        debtCount > 1 ? 's' : ''
                      }`}</Typography>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {!isMobile && debtCount && debtCount > 0 ? (
                <div className={styles.adviceFilled}>
                  <Typography>{`${debtCount} ${pluralDebt} vencida${
                    debtCount > 1 ? 's' : ''
                  }`}</Typography>
                </div>
              ) : (
                <></>
              )}
            </div>
            <StatementsTable isPaymentPlan={false} productId={subscriptionBuyerId} />
          </div>
          {subscriptionBuyer && (
            <Fragment>
              <CardsPopup
                state={{ open: openPopup, setOpen: setOpenPopup }}
                projectName={subscriptionBuyer.subscription?.name}
                card={subscriptionBuyer.card?.id || ''}
                productId={subscriptionBuyer.id}
                show={true}
                cards={cards}
                PACs={PACs}
              />
              <PaymentsPopup
                state={{ open: openPaymentsPopup, setOpen: setOpenPaymentsPopup }}
                amount={amountToPay}
                statements={statementsCart}
                cards={cards}
              />
            </Fragment>
          )}
          {amountToPay > 0 && <StatementsCart setOpenPaymentsPopup={setOpenPaymentsPopup} />}
        </Fragment>
      )}
    </div>
  );
};

export default SubscriptionShow;
